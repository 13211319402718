<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col cols="8">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Details</label
            >
            <b-row>
              <b-col cols="6">
                <b-form-group label="Code" label-for="code">
                  <b-form-input
                    id="code"
                    placeholder="Code"
                    v-model="form.code"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Category" label-for="category">
                  <b-form-select
                    id="category"
                    v-model="form.selectedCategory"
                    :options="form.optionsCategory"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    id="name"
                    placeholder="Name"
                    v-model="form.name"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Unit" label-for="unit">
                  <b-form-select
                    id="unit"
                    v-model="form.selectedUnit"
                    :options="form.optionsUnit"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedUnit }}</strong> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Vendor" label-for="vendor">
                  <b-form-select
                    id="vendor"
                    v-model="form.selectedVendor"
                    :options="form.optionsVendor"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedVendor }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Price" label-for="price">
                  <cleave
                    id="price"
                    placeholder="Price"
                    v-model="form.price"
                    class="form-control"
                    :raw="false"
                    :options="options.number"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Selling Price" label-for="selling_price">
                  <cleave
                    id="selling_price"
                    placeholder="Selling Price"
                    v-model="form.selling_price"
                    class="form-control"
                    :raw="false"
                    :options="options.number"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Description" label-for="description">
              <quill-editor v-model="form.description" />
            </b-form-group>
            
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mt-2"
              @click="addProduct()"
            >
              Submit
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Image"
              label-for="Image"
              invalid-feedback="Image is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Photo"
                rules="required"
              >
                <image-uploader
                  id="file"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        selectedUnit: null,
        optionsUnit: [],
        selectedCategory: null,
        optionsCategory: [],
        price: null,
        selling_price: null,
        selectedVendor: null,
        optionsVendor: [],
        description: ``,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      errors: "",
      errMessage: "",
      hasImage: "",
      image: "",
      photo: "",
      errMessageImage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getUnits();
    this.getCategories();
    this.getVendor();
  },
  updated() {
    console.log(document.getElementById("file").files[0]);
  },
  methods: {
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    getUnits() {
      this.$http.get("product/units/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsUnit.push({
          value: null,
          text: "Please select 1 Unit",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsUnit.push($data);
        }
        // console.log(this.form.optionsUnit)
      });
    },
    getCategories() {
      this.$http.get("product/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsCategory.push({
          value: null,
          text: "Please select 1 Category",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.form.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    getVendor() {
      this.$http.get("vendors").then((response) => {
        // console.log(response.data.data)
        this.form.optionsVendor.push({
          value: null,
          text: "Please select 1 Vendor",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsVendor.push($data);
        }
        // console.log(this.form.optionsVendor)
      });
    },
    addProduct() {
      var newPrice = this.form.price;
      newPrice = newPrice.replace(/\,/g, "");
      var newSellingPrice = this.form.selling_price;
      newSellingPrice = newSellingPrice.replace(/\,/g, "");
      this.$http
        .post("product/add", {
          code: this.form.code,
          name: this.form.name,
          unit_id: this.form.selectedUnit,
          category_id: this.form.selectedCategory,
          price: newPrice,
          selling_price: newSellingPrice,
          image: this.image,
          vendor_id: this.form.selectedVendor,
          description: this.form.description,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Product",
              variant: "success",
            },
          });
          location.href = "/product/products";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>